export const SESSION_TIMEOUT = 30;

const ONE_MINUTE = 60 * 1000;
export const FIVE_MINUTES = 5 * ONE_MINUTE;
// export const TEN_MINUTES = 10 * ONE_MINUTE;

export const FOCUS_THROTTLE_INTERVAL_SHORT = 30 * 1000; // 30 seconds
export const FOCUS_THROTTLE_INTERVAL = 5 * 60 * 1000; // 5 minutes

export const ROOT_LAYOUT_DOM_NODE_ID = 'oxen-root-layout';
