import { styled } from 'styled-components';
import { colors, toRgba } from '@/styles';

const PANEL_WIDTH = '400px';

export const HelpPanelPageWrapper = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
`;

export const HelpPanelContainer = styled.div<{ $isOpen?: boolean }>`
  position: relative;
  width: ${({ $isOpen }) => ($isOpen ? PANEL_WIDTH : '0px')};
  transition: ${({ $isOpen }) => ($isOpen ? 'width 0.25s ease-in-out' : 'width 0.25s ease-in-out')};
`;

export const HelpPanel = styled.div<{ $isOpen?: boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${colors.semantic.information.light};
  border-left: 1px solid ${colors.semantic.information.border};
  /* transition: ${({ $isOpen }) => ($isOpen ? 'width 0.25s ease-in-out' : 'width 0.25s ease-in-out')}; */

  .tab {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 10px; */
    background-color: ${colors.semantic.information.light};
    top: 20%;
    z-index: 1;
    /* box-sizing: border-box; */

    left: -40px;
    width: 40px;
    height: 120px;

    border: 1px solid ${colors.semantic.information.border};
    border-right: none;
    border-radius: 5px 0 0 5px;
    font-weight: bold;
    white-space: nowrap;
    box-shadow: -5px -5px 5px 0px ${toRgba(colors.primary.black, 0.1)};
    cursor: pointer;

    label {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 16px;
      transform: rotate(-90deg);
    }
  }

  .content {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    padding: 20px;
    width: ${PANEL_WIDTH};
    height: 100%;
    box-shadow: -5px -5px 5px 0px ${toRgba(colors.primary.black, 0.1)};

    .user-display {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .step {
      border: 2px solid ${colors.semantic.information.border};
      padding: 10px;
      border-radius: 5px;
    }
  }
  /* display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')}; */
`;

export const HelpPanelAdminDisplay = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 5px; */
  ul {
    padding-inline-start: 15px;
    li {
      padding: 2px 0;
    }
  }
`;

export const HelpPanelAdminDisplayListItem = styled.li<{ $available?: boolean }>`
  cursor: pointer;
  text-decoration: ${({ $available }) => ($available ? 'underline' : 'none')};
  color: ${({ $available }) => ($available ? colors.semantic.link : colors.semantic.disabled)};
`;

// export const HelpPanelStep = styled.div<{ $available?: boolean }>`
//   border: 2px solid ${colors.semantic.information.border};
//   padding: 10px;
//   border-radius: 5px;
// `;
