'use client';
import { formatDate } from '@/lib/dateTime';
import { Card, Stack, Row } from '@/ui/primatives';
import { TruncatedContentText } from '@/components/Content/ContentBlock';
import { PostOrPage } from '@tryghost/content-api';
import Link from 'next/link';

const BlogPostSummary: React.FC<{
  post: PostOrPage;
  tagFlag?: boolean;
}> = ({ post, tagFlag }) => {
  return (
    <Link href={`/blog/${post.slug}`} key={post.slug}>
      <Card gap={10} style={{ padding: '0px' }}>
        <Row alignItems="stretch" justifyContent="stretch" gap={0} className="mobile-wrap">
          <Row style={{ width: '100%', maxWidth: '300px', height: 'auto' }} alignItems="center" className="hide-mobile">
            <img
              src={post.feature_image ?? ''}
              alt={post.title}
              style={{ width: '100%', maxWidth: '350px', height: 'auto', objectFit: 'cover' }}
            />
          </Row>
          <Stack gap={0} style={{ padding: '25px', width: '100%' }}>
            <div style={{ fontWeight: '400', fontSize: '25px' }}>{post.title}</div>
            <div style={{ fontWeight: '250', wordSpacing: '3px', fontSize: '13px' }}>
              <TruncatedContentText content={post.plaintext} length={180} />
            </div>
            <Stack justifyContent="flex-end" gap={0} style={{ height: '100%' }}>
              <div style={{ borderTop: '1px solid #ccc', margin: '10px 0' }}></div>
              <Row gap={0} justifyContent="space-between" alignItems="center">
                <Row gap={10} alignItems="center">
                  <img
                    src={post.primary_author?.profile_image ?? ''}
                    alt={post.primary_author?.name}
                    style={{ width: '30px', height: '30px', borderRadius: '50%' }}
                  />
                  <Stack gap={3}>
                    <div style={{ fontWeight: '200', fontSize: '12px' }}>{post.primary_author?.name}</div>
                    <Row gap={0}>
                      <div style={{ fontWeight: '200', display: 'flex', fontSize: '12px' }}>
                        {formatDate(new Date(post.published_at ?? ''))}
                        {tagFlag && post.primary_tag?.name && (
                          <div
                            style={{
                              textTransform: 'capitalize',
                              paddingLeft: '4px',
                            }}
                          >
                            - {post.primary_tag.name}
                          </div>
                        )}
                      </div>
                    </Row>
                  </Stack>
                </Row>
                {post.reading_time && (
                  <div style={{ fontWeight: '200', fontSize: '12px', paddingLeft: '15px' }}>
                    {`${post.reading_time} min read`}
                  </div>
                )}
              </Row>
            </Stack>
          </Stack>
        </Row>
      </Card>
    </Link>
  );
};

export default BlogPostSummary;
