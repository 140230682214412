'use client';
import { Stack, Row } from '@/ui/primatives';
import { PostOrPage, PostsOrPages, Tags } from '@tryghost/content-api';
import { useState } from 'react';
import BlogPostSummary from './BlogPostSummary';

const PastDives: React.FC<{
  papers: PostsOrPages;
  isCommunity?: boolean;
  title?: string;
  tagFlag?: boolean;
  tags?: Tags;
  currentTag?: string;
}> = ({ papers, isCommunity = false, title = 'Recent', tagFlag = false, tags, currentTag }) => {
  const [selectedTag, setSelectedTag] = useState<string>(currentTag || '');
  const handleTagChange = (selectedTag: string) => {
    setSelectedTag(selectedTag);
    window.location.href = `/blog${selectedTag ? `?tag=${selectedTag}` : ''}`;
  };

  const tagOptions = [
    { label: 'All', value: '' },
    ...(tags?.map((tag) => ({ label: tag.name, value: tag.slug })) || []),
  ];

  if (papers && papers.length < 1) {
    return !isCommunity ? (
      <Row gap={30}>
        {tagOptions.map((option) => (
          <a
            key={option.value}
            className={selectedTag === option.value ? 'link selected' : 'link'}
            onClick={(e) => {
              e.preventDefault();
              handleTagChange(option.value);
            }}
            href={`/blog?tag=${option.value}`}
            style={{
              textTransform: 'capitalize',
              fontWeight: selectedTag === option.value ? '600' : 'normal',
            }}
          >
            {option.label}
          </a>
        ))}
      </Row>
    ) : null;
  }

  return (
    <Stack gap={40}>
      {!isCommunity && (
        <Row gap={30}>
          {tagOptions.map((option) => (
            <a
              key={option.value}
              className={selectedTag === option.value ? 'link selected' : 'link'}
              onClick={(e) => {
                e.preventDefault();
                handleTagChange(option.value);
              }}
              href={`/blog?tag=${option.value}`}
              style={{
                textTransform: 'capitalize',
                fontWeight: selectedTag === option.value ? '600' : 'normal',
              }}
            >
              {option.label}
            </a>
          ))}
        </Row>
      )}
      {papers.map((paper: PostOrPage) => (
        <BlogPostSummary post={paper} tagFlag={tagFlag} />
      ))}
    </Stack>
  );
};

export default PastDives;
