'use client';

import styled from 'styled-components';
import { colors, toRgba } from '@/styles';

export const Flash = styled.div<{ $center?: boolean; $position?: 'inline' | 'header' }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: ${({ $position, $center }) => ($position === 'header' ? 'center' : $center ? 'center' : 'flex-start')};
  padding: 20px;
  border-color: ${colors.secondary.lightGray3};
  border-style: solid;
  border-width: ${(props) => (props.$position === 'header' ? '1px 0 1px 0' : '1px')};
  background: ${colors.secondary.lightGray1};
  border-radius: ${(props) => (props.$position === 'header' ? '0' : '5px')};

  svg {
    margin-right: 1rem;
  }

  p {
    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const FlashCloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 100px;
  padding: 2px;
  top: 0.5rem;
  right: 0.5rem;
  & svg {
    height: 1rem;
    width: 1rem;
    margin-right: 0;
  }
  &:hover {
    cursor: pointer;
    border: 1px solid ${colors.primary.black};
    background: ${toRgba(colors.primary.white, 0.5)};
  }
`;

export const FlashInfo = styled(Flash)`
  border-color: ${colors.semantic.information.dark};
  background: ${colors.semantic.information.light};
`;

export const FlashSuccess = styled(Flash)`
  border-color: ${colors.semantic.positive.dark};
  background: ${colors.semantic.positive.light};
`;

export const FlashWarning = styled(Flash)`
  border-color: ${colors.semantic.notice.dark};
  background: ${colors.semantic.notice.light};
`;

export const FlashDanger = styled(Flash)`
  border-color: ${colors.semantic.negative.dark};
  background: ${colors.semantic.negative.light};
`;
