// 'use client';
import React from 'react';

import * as Styled from './styled';

import Link from 'next/link';
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  theme?: any;
  disabled?: boolean;
  rounded?: boolean;
  stretch?: boolean;
  download?: boolean | string; // download attribute for anchor tags
  small?: boolean;
  smaller?: boolean;
  inline?: boolean;
  tiny?: boolean;
  nowrap?: boolean;
  className?: string;
  testId?: string;
  href?: string;
  target?: string;
  hideMobile?: boolean;
}

const mapStyleProps = (props: ButtonProps) => {
  const { testId, stretch, rounded, small, smaller, tiny, inline, nowrap, hideMobile, ...rest } = props;
  const dataTestId = testId ? `button-${testId}` : undefined;
  const styledProps = {
    ...rest,
    $stretch: stretch,
    $rounded: rounded,
    $small: small,
    $smaller: smaller,
    $tiny: tiny,
    $inline: inline,
    $nowrap: nowrap,
    $hideMobile: hideMobile,
    'data-testid': dataTestId,
  };
  return styledProps;
};

const Button: React.FC<ButtonProps> = ({
  children = 'Default',
  disabled,
  rounded,
  stretch,
  download,
  small = false,
  smaller = false,
  inline = false,
  nowrap,
  theme = {},
  className,
  testId,
  ...props
}) => {
  const dataTestId = testId ? `button-${testId}` : undefined;
  return (
    <Styled.Button
      data-testid={dataTestId}
      disabled={disabled}
      $stretch={stretch}
      $rounded={rounded}
      $small={small}
      $inline={inline}
      $nowrap={nowrap}
      className={className}
      {...props}
    >
      {children}
    </Styled.Button>
  );
};

const LinkWrapper: React.FC<{
  href?: string;
  target?: string;
  download?: boolean | string;
  disabled?: boolean;
  children: React.ReactNode;
}> = ({ children, href, download, target, disabled }) => {
  return href && !disabled ? (
    <Link href={href} passHref target={target} download={download}>
      {children}
    </Link>
  ) : (
    children
  );
};

export const Black: React.FC<ButtonProps> = ({ children, href, target, ...props }) => {
  const styledProps = mapStyleProps(props);
  return (
    <LinkWrapper href={href} target={target} download={props.download} disabled={props.disabled}>
      <Styled.Button {...styledProps}>{children}</Styled.Button>
    </LinkWrapper>
  );
};

export const Primary: React.FC<ButtonProps> = ({ children, href, target, ...props }) => {
  const styledProps = mapStyleProps(props);
  return (
    <LinkWrapper href={href} target={target} download={props.download} disabled={props.disabled}>
      <Styled.PrimaryButton {...styledProps}>{children}</Styled.PrimaryButton>
    </LinkWrapper>
  );
};

export const PrimaryOutline: React.FC<ButtonProps> = ({ children, href, target, ...props }) => {
  const styledProps = mapStyleProps(props);
  return (
    <LinkWrapper href={href} target={target} download={props.download} disabled={props.disabled}>
      <Styled.PrimaryOutlineButton {...styledProps}>{children}</Styled.PrimaryOutlineButton>
    </LinkWrapper>
  );
};

export const Secondary: React.FC<ButtonProps> = ({ children, href, target, ...props }) => {
  const styledProps = mapStyleProps(props);
  return (
    <LinkWrapper href={href} target={target} download={props.download} disabled={props.disabled}>
      <Styled.SecondaryButton {...styledProps}>{children}</Styled.SecondaryButton>
    </LinkWrapper>
  );
};

export const Basic: React.FC<ButtonProps> = ({ children, href, target, ...props }) => {
  const styledProps = mapStyleProps(props);
  return (
    <LinkWrapper href={href} target={target} download={props.download} disabled={props.disabled}>
      <Styled.BasicButton {...styledProps}>{children}</Styled.BasicButton>
    </LinkWrapper>
  );
};

export const Text: React.FC<ButtonProps> = ({ children, href, target, ...props }) => {
  const styledProps = mapStyleProps(props);
  return (
    <LinkWrapper href={href} target={target} download={props.download} disabled={props.disabled}>
      <Styled.TextButton {...styledProps}>{children}</Styled.TextButton>
    </LinkWrapper>
  );
};

export const Cta: React.FC<ButtonProps> = ({ children, href, target, ...props }) => {
  const styledProps = mapStyleProps(props);
  return (
    <LinkWrapper href={href} target={target} download={props.download} disabled={props.disabled}>
      <Styled.CtaButton {...styledProps}>{children}</Styled.CtaButton>
    </LinkWrapper>
  );
};

export const Magic: React.FC<ButtonProps & { glow?: boolean; invert?: boolean }> = ({
  children,
  href,
  target,
  glow = true,
  invert,
  ...props
}) => {
  const styledProps = mapStyleProps(props);
  return (
    <LinkWrapper href={href} target={target} download={props.download} disabled={props.disabled}>
      {props.disabled ? (
        <>
          {invert ? (
            <Styled.MagicButton {...styledProps}>{children}</Styled.MagicButton>
          ) : (
            <Styled.PrimaryButton {...styledProps}>{children}</Styled.PrimaryButton>
          )}
        </>
      ) : (
        <Styled.MagicButtonWrapper $glow={glow} $borderWidth={1}>
          {invert ? (
            <Styled.MagicButton {...styledProps}>{children}</Styled.MagicButton>
          ) : (
            <Styled.PrimaryButton {...styledProps}>{children}</Styled.PrimaryButton>
          )}
        </Styled.MagicButtonWrapper>
      )}
    </LinkWrapper>
  );
};

export default Object.assign(Button, { Primary, PrimaryOutline, Secondary, Black, Basic, Text, Cta, Magic });
