import React from 'react';
import * as Tooltip from '@/ui/Tooltip';
import * as Styled from './styled';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
export type TagVariant =
  | 'white'
  | 'info'
  | 'error'
  | 'success'
  | 'warning'
  | 'default'
  | 'subtle'
  | 'transparent'
  | 'primary';

export interface TagProps extends React.HTMLAttributes<HTMLSpanElement> {
  children?: React.ReactNode;
  variant?: TagVariant;
  counter?: number;
  border?: boolean;
  pill?: boolean;
  small?: boolean;
  className?: string;
  tooltip?: string;
  tooltipSide?: TooltipPrimitive.TooltipContentProps['side'];
  tooltipSmall?: boolean;
}

const ToolTipWrapper: React.FC<TagProps> = ({
  children = '...',
  variant = 'default',
  counter,
  pill,
  small,
  className,
  border,
  tooltip,
  tooltipSide,
  tooltipSmall,
  ...rest
}) => {
  const TagInstance = (
    <Tag variant={variant} counter={counter} pill={pill} small={small} className={className} border={border} {...rest}>
      {children}
    </Tag>
  );

  if (!tooltip) {
    return TagInstance;
  }

  return (
    // <Tooltip.Provider>
    <Tooltip.Root>
      <Tooltip.Trigger asChild>
        <span>
          <Tag
            variant={variant}
            counter={counter}
            pill={pill}
            small={small}
            className={className}
            border={border}
            {...rest}
          >
            {children}
          </Tag>
        </span>
      </Tooltip.Trigger>
      <Tooltip.Content side={tooltipSide} small={tooltipSmall}>
        {tooltip}
      </Tooltip.Content>
    </Tooltip.Root>
    // </Tooltip.Provider>
  );
};

const Tag: React.FC<TagProps> = ({
  children = '...',
  variant = 'default',
  counter,
  pill,
  small,
  className,
  border,
  ...rest
}) => {
  return (
    <Styled.Tag className={className} $pill={pill} $small={small} $variant={variant} $withBorder={border} {...rest}>
      {children}
      {!!counter && <Styled.Counter>{counter}</Styled.Counter>}
    </Styled.Tag>
  );
};

export default ToolTipWrapper;
