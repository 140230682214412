import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const MobileMenuWapper = styled.div`
  display: none;

  @media (max-width: ${breakPoints.tablet}) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hamburger-react {
    z-index: 100;
    margin-right: -10px;
  }
`;

export const MobileMenu = styled.div<{ open: boolean }>`
  position: absolute;
  background-color: ${(props) => (props.open ? 'rgba(255, 255, 255, 0.75)' : 'rgba(255, 255, 255, 0)')};
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
  bottom: 0px;
  transition: 0.4s;
  z-index: 99;
  transform: translateX(${(props) => (props.open ? '0' : '-100%')});
  padding-top: 55px;

  ul {
    background-color: ${colors.primary.white};
    padding: 0px;
    border-top: 1px solid ${colors.secondary.darkGray1};
    box-shadow: ${(props) => (props.open ? '0px 25px 20px 20px rgba(0, 0, 0, 0.25)' : 'none')};
    li {
      list-style: none;
      border-bottom: 1px solid ${colors.secondary.darkGray1};
      padding: 10px;
    }
  }
`;

export const NavigationItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px;
  & > * {
    padding: 1px;
    box-sizing: initial;
  }
`;
